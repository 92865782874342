import Home from './components/Home';
import './css/Home.css';

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Home />
    </div>
  );
}

export default App;
